import type { HeadingColors } from '@farmersdog/corgi';
import type { RecipeNames } from '@farmersdog/constants';

// Treatments
export type ToggleTreatment = 'on' | 'off' | 'control';
export type RecipesBelowTheFoldTreatment =
  | 'control'
  | 'content'
  | 'reviews'
  | 'none';
export type FrequenciesAtCheckoutTreatment = 'trans_box' | '28_cap' | 'control';
export type PhoneOnMePageTreatment =
  | 'off'
  | 'required_phone'
  | 'optional_phone'
  | 'optional_dropdown_opt_in';
export type RecipeNamesTreatment = 'off' | 'vegetables' | 'ingredients';

export enum MixingPlanTreatments {
  off = 'off',
  control = 'control',
  treatmentControl = 'treatmentControl',
  treatmentA = 'treatmentA',
  treatmentB = 'treatmentB',
  treatmentC = 'treatmentC',
}

export enum ToggleTreatments {
  off = 'off',
  on = 'on',
  control = 'control',
}

export enum PostCheckoutReferralTreatments {
  off = 'off',
  control = 'control',
  treatmentA = 'treatmentA',
  treatmentB = 'treatmentB',
}

export enum PostCheckoutReferralPercentageTreatments {
  off = 'off',
  '20_percent_off' = '20_percent_off',
  '60_percent_off' = '60_percent_off',
  '100_percent_off' = '100_percent_off',
}

export enum RecipePagePackOrderEnum {
  off = 'off',
  chicken_first = 'chicken_first',
}

export enum DefaultDiscountTreatments {
  off = 'off',
  control = 'control',
  discount20 = 'discount20',
  discount50 = 'discount50',
}

export enum CvrSignupCardSelectorsTreatments {
  off = 'off',
  with_continue_button = 'with_continue_button',
  without_continue_button = 'without_continue_button',
}

export type CheckoutSuccessExperimentTreatments =
  | 'confidence'
  | 'plan_info'
  | 'simplified'
  | 'account_cta'
  | 'off';

export enum CvrSmsOptInTreatments {
  off = 'off',
  transition = 'transition',
  perfect_plan = 'perfect_plan',
}

export enum CvrSurpriseHigherDiscountTreatments {
  off = 'off',
  recipes = 'recipes',
  checkout = 'checkout',
}

export enum CvrPriorFoodTypeProgressionTreatments {
  off = 'off',
  on = 'on',
}

export enum HomepageHeroImageTreatments {
  Control = 'control',
  Off = 'off',
  LovedByHumans = 'loved_by_humans',
  CtaOnly50Off = 'cta_only_50_off',
  CtaOnlySeePlansAndPricing = 'cta_only_see_plans_and_pricing',
}

export enum HomepageEndToEndRedesignTreatments {
  Off = 'off',
  Control = 'control',
  CurrentHeroDog = 'current_hero_dog',
  OptimizedHero = 'optimized_hero',
  CurrentFormat = 'current_format_hero',
}

export enum ReviewsPageRedesignTreatments {
  off = 'off',
  on = 'on',
}

export enum SkipGetStartedTreatments {
  off = 'off',
  same_copy = 'same_copy',
  new_copy = 'new_copy',
  no_copy = 'no_copy',
}

export enum OnbRequestPasswordResetTurnstileTreatment {
  AlwaysChallenge = 'always_challenge',
  AlwaysErrorClient = 'always_error_client',
  AlwaysErrorServer = 'always_error_server',
  AlwaysPass = 'always_pass',
  Control = 'control',
  Default = 'default',
  Off = 'off',
  On = 'on',
  TokenAlreadyUsed = 'token_already_used',
}

export enum CustomerClinicSearchTreatments {
  off = 'off',
  on = 'on',
  hide_search = 'hide_search',
}

export enum ReferralsGiftABoxVariant {
  control = 'control',
  off = 'off',
  with_ucg = 'with_ucg',
}

export enum AcMe3PageTreatments {
  off = 'off',
  me3_page_only_copy_change = 'me3_page_only_copy_change',
}

// Configs

export interface RecipePagePackOrderConfig {
  recipeOrder: RecipeNames[];
}

export interface ShowPetsInterstitialConfig {
  type: string;
  headline: string;
  underline: string;
  color: (typeof HeadingColors)[number];
  background: string;
  loops: number;
}

export interface ShowHolidayAnnouncementConfig {
  headline: string;
  underline: string;
  endDate: string;
  cutoffStart: string;
  cutoffEnd: string;
}

export interface HomepageSectionHeadersConfig {
  sectionBowl: string;
  sectionWave: string;
  sectionPackage: string;
}

export interface HdyhauContentConfig {
  header: string;
  underline: string;
  offer: string;
}

export interface RecipeNamesConfig {
  TURKEY: string;
  BEEF: string;
  PORK: string;
  CHICKEN_AND_GREENS: string;
}

interface CustomerCxPhoneNumberConfigItem {
  email: boolean;
  phone: boolean;
}
export interface CustomerCxPhoneNumberConfig {
  footer: CustomerCxPhoneNumberConfigItem;
  faq: CustomerCxPhoneNumberConfigItem;
}

export interface RetPawToggleConfig {
  profile: boolean;
}

// Attributes

export interface FrequenciesAtCheckoutAttributes extends SplitIO.Attributes {
  regularFrequency: number;
}

export interface GlobalDiscountAttributes extends SplitIO.Attributes {
  referrerCode: string;
  referrerType: string;
}

export interface FeatureWithCouponAttributes extends SplitIO.Attributes {
  couponCode: string;
  couponType: string;
}
